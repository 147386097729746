@charset "UTF-8";

html, body, div, p, main, footer, aside, input, pre, textarea {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    color: #ececf1;
    font-family: Helvetica, sans-serif;
}

html, body {
    width: 100%;
    height: 100%;
    background-color: #343541;
    font-family: Helvetica, sans-serif;
}